//===================================================
//	content setting
//===================================================

//content_size

$full-pc_size:1920;
$pc_size:1240;
$tb_size:960;
$sp_size:560;
$sp-s_size:375;

$cont_w:	1200px;//コンテンツ全体サイズ
$main_w:	1240px;//メインコンテンツサイズ
$aside_w:	240px;//サイドサイズ

//mediaquery

$x-pc: #{$full-pc_size}px; //PC：背景とかの設定用 FullHD
$pc: #{$pc_size}px; //PC
$tb: #{$tb_size}px; //タブレット
$sp: #{$sp_size}px; //スマートフォン
$sp-s: #{$sp-s_size}px;//iPhone 以下

//side

$side_use: none; //サイドどこで使うか all,lv2,blog,none
$side_position: left; //サイドの位置

//layout

$base_pad: 4%;
$base_mar: 1rem;


$maxcol:12; //カラム数
$col-margin:20px; //カラム間余白


//基本コンテンツ幅固定するかどうか（100%幅で構築するならtrue）
$cont100box:true;

//コンテンツ全体に余白をつけるか（余白ありきでデザインされていない場合true）
$use_contmargin:false;
$sp_contmargin: 4%;
$pc_contmargin: 20px;
