@use "../_mixins/break-points" as *;
@use "../base" as *;
/*----------------------------------------------------------------
	contents
----------------------------------------------------------------*/
.txt {
	&:not(:last-of-type) {
		margin-bottom: 2rem;
	}
}

/* main */
.main {
	@include clearfix;
	img {
		display: block;
	}
}

/* contents */

.l-cont {
	@include l-cont;
	@include clearfix;
}

.l-box {
	z-index: 1;
	position: relative;
}

.not-found .cont__wrap {
	padding-top: 10rem;
	padding-bottom: 9rem;
	@include media(tb) {
		padding-top: calc(12rem + 50 * (100vw - #{$sp}) / #{$pc_size - $sp_size});
		padding-bottom: calc(9rem + 50 * (100vw - #{$sp}) / #{$pc_size - $sp_size});
	}
	@include media(pc) {
		padding-top: 20rem;
		padding-bottom: 16rem;
	}
}

.cont {
	&__wrap {
		padding-top: 9rem;
		padding-bottom: 6rem;
		@include media(tb) {
			padding-top: calc(9rem + 50 * (100vw - #{$sp}) / #{$pc_size - $sp_size});
			padding-bottom: calc(6rem + 50 * (100vw - #{$sp}) / #{$pc_size - $sp_size});
		}
		@include media(pc) {
			padding-top: 14rem;
			padding-bottom: 14rem;
		}
	}
	&__inner {
		background: rgba($black, 0.5);
		padding: 3rem;
		color: $o-white;
		@include radius;
		@include media(pc_s) {
			padding: 5rem;
		}
		@include media(sp_less) {
			padding: 3rem 1.5rem;
		}
	}
	&__tit {
		@include f-s_sp(1.8, 12);
		font-family: 'Zen Maru Gothic', sans-serif;
		@include f-w(700);
		@include l-sp(3px);
		margin-bottom: 3rem;
		text-align: center;
		color: $point_txt;
		@include media(pc) {
			font-size: 3.2rem;
			margin-bottom: 5rem;
		}
		span {
			font-family: 'Mandali', sans-serif;
			display: block;
			font-size: 0.7em;
			color: $main_c;
			@include f-w(600);
			@include l-sp(1px);
			
		}
	}
}
.top-cont02 .tit-02,.top-cont05 .tit-05,
.works .cont__tit 
{
	color: #fff!important;
}

.c-box__tit-02{
padding: 1rem 0;
border-top: solid 2px $main_c;

text-align: center;
}
/* contents box */
.c-box {
	text-align: left;
	margin-bottom: 2rem;
	@include media(sp_less) {
		@include m-a;
	}
	&:not(:last-child) {
		margin-bottom: 2rem;
	}
	&__tit {
		text-align: center!important;
		@include f-s_sp(1.6, 2);
		@include f-w(500);
		margin-bottom: 0.75rem;

		@include media(pc) {
			font-size: 1.8rem;
		}
	}
	&__img {
		position: relative;
		margin-bottom: 1rem;
		img {
			@include m-a;
			display: block;
		}
	}
	&__txt-02 {
		height: 150px;
		overflow-y: auto;
	}
}
.c-box h4 {
	margin-bottom: 1.5rem;
}

.c-box figure {
	margin-bottom: 1rem;
}
.c-box figcaption {
	font-size: 1.4rem;
	padding: 7px 0 0;
	text-align: center;
}
.c-box p {
	line-height: 1.5;
	padding-bottom: 10px;
	padding-bottom: 1rem;
	text-align: left;
}

.c-box__txt-01 .c-btn,
.c-box__txt-02 .c-btn {
	position: absolute;
	left: 1.5rem;
	bottom: 1.5rem;
	width: calc(100% - 3rem);
}

.frame {
	text-align: left;
	background: rgba($white, 0.8);
	padding: 1.5rem;
	height: 100%;
	border-radius: 1rem!important;
	display: block;
	color: $txt_c;
	@include radius(3);
	@include dec-none;
}

.frame:hover{
	border-radius: 1rem!important;
}

a.img-scale figure{
	border-radius: 1rem;
}