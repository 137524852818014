@use "../_mixins/break-points" as *;
@use "../base" as *;
/*--------------------------------------------------------------------/
  	nav global
/--------------------------------------------------------------------*/
#global {
	z-index: 1;
	position: relative;
	font-family: "Noto Sans JP", sans-serif;
	float: right;
	@include media(pc_less) {
		display: none;
	}
}
.global__items {
	@include flex-end;
	width: 100%;
	position: relative;
	& > li {
		&:not(:last-of-type) {
			margin-right: 0.8rem;
		}
	}
	& a {
		display: inline-block;
		@include dec-none;
		padding: 0.1rem 0.6rem;
		@include transition;
		@include line-h(1.4);
		@include f-w(500);
		color: $txt_c;
		position: relative;
		&::before {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			width: 0;
			height: 1px;
			@include transition;
			background: $main_c;
		}
		&:hover {
			&::before {
				width: 100%;
			}
		}
	}
}

@include media(sp_less) {
	@media all and (-ms-high-contrast: none) {
		*::-ms-backdrop,
		#global .global-items > li a {
			height: 100%;
		}
	}
}

@include media(pc) {
	.drawer-hamburger,
	.global__phone {
		display: none;
	}
}
