@use "../_mixins/break-points" as *;
@use "../base" as *;

/*--------------------------------------------------------------------/
	column
/--------------------------------------------------------------------*/
.l-col {
	&4 {
		@include media(tb) {
			@include flex-wrap;
			& > * {
				width: 48.96%;
				margin-bottom: 2rem;
				vertical-align: top;
				&:not(:nth-of-type(2n)) {
					margin-right: 2.08%;
				}
			}
		}
		@include media(pc_s) {
			& > * {
				height: auto;
				width: 35%;
				&:nth-of-type(odd) {
					margin-left: 11%;
				}
				&:not(:nth-of-type(2n)) {
					margin-right: 8%;
				}
			}
		}
		@include media(pc) {
			& > * {
				width: 23.44%;
				margin-bottom: 3rem;
				&:nth-of-type(odd) {
					margin-left: 0;
				}
				&:not(:nth-of-type(2n)) {
					margin-right: 0;
				}
				&:not(:nth-of-type(4n)) {
					margin-right: 2.08%;
				}
			}
		}
		@include media(sp_less) {
			& > *:not(:last-child) {
				margin-bottom: 1rem;
			}
		}
	}
	&3 {
		@include media(tb) {
			@include flex-wrap;
			& > * {
				width: 48.96%;
				margin-bottom: 2rem;
				vertical-align: top;
				&:not(:nth-of-type(2n)) {
					margin-right: 2.08%;
				}
			}
		}
		@include media(pc_s) {
			& > * {
				height: auto;
				width: 35%;
				&:nth-of-type(odd) {
					margin-left: 11%;
				}
				&:not(:nth-of-type(2n)) {
					margin-right: 8%;
				}
			}
		}
		@include media(pc) {
			& > * {
				width: 31.94%;
				height: auto;
				&:nth-of-type(odd) {
					margin-left: 0;
				}
				&:not(:nth-of-type(2n)) {
					margin-right: 0;
				}
				&:not(:nth-of-type(3n)) {
					margin-right: 2.08%;
				}
			}
		}
		@include media(sp_less) {
			& > *:not(:last-child) {
				margin-bottom: 1rem;
			}
		}
	}
	&2 {
		@include media(tb) {
			@include flex-wrap;
			& > * {
				width: 48.96%;
				&:not(:nth-of-type(2n)) {
					margin-right: 2.08%;
				}
			}
		}
		@include media(pc_s) {
			& > * {
				height: auto;
				width: 35%;
				&:nth-of-type(odd) {
					margin-left: 11%;
				}
				&:not(:nth-of-type(2n)) {
					margin-right: 8%;
				}
			}
		}
		@include media(sp_less) {
			& > *:not(:last-child) {
				margin-bottom: 1rem;
			}
		}
	}
}

.l-col2 .c-box__img,
.l-col3 .c-box__img,
.l-col4 .c-box__img {
	@include media(tb) {
		a {
			overflow: hidden;
			position: relative;
		}
		img {
			height: 29.93vw;
		}
	}
	@include media(pc_s) {
		a {
			height: 21.49vw;
			max-height: 280px;
		}
		img {
			height: 21.49vw;
			max-height: 280px;
		}
	}
}

.l-col2 .c-box__img {
	@include media(pc_s) {
		a {
			height: 21.49vw;
			max-height: 280px;
		}
		img {
			height: 21.49vw;
			max-height: 280px;
		}
	}
}
.l-col3 .c-box__img {
	@include media(pc) {
		a {
			height: 19.56vw;
			max-height: 256px;
		}
		img {
			max-height: 256px;
		}
	}
}
.l-col4 .c-box__img {
	@include media(pc) {
		a {
			height: 14.36vw;
			max-height: 188px;
		}
		img {
			max-height: 188px;
		}
	}
}

.slider-col3 .c-box__img,
.slider-col4 .c-box__img {
	a {
		overflow: hidden;
		position: relative;
	}
	img {
		height: 50.31vw;
	}
	@include media(tb) {
		img {
			height: 25.93vw;
		}
	}
}
.slider-col3 .works-list2,
.slider-col4 .works-list2 {
	height: 56.6vw;
	@include media(tb) {
		height: 30.48vw;
		max-height: 356px;
	}
}
.slider-col3 .c-box__img {
	@include media(pc) {
		a {
			height: 17.6vw;
			max-height: 239px;
		}
		img {
			max-height: 239px;
		}
	}
}
.slider-col3 .works-list2 {
	@include media(pc) {
		height: 22.21vw;
		max-height: 279px;
	}
}
.slider-col4 .c-box__img {
	@include media(pc) {
		a {
			height: 12.39vw;
			max-height: 168px;
		}
		img {
			max-height: 168px;
		}
	}
}
.slider-col4 .works-list2 {
	@include media(pc) {
			height: 16.32vw;
			max-height: 205px;
	}
}
